import React, { AppContainer, Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import RequireAuth from 'react-auth-kit';
import PrivateRoute from './PrivateRoute';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))


class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="/login" name="Login Page" element={<Login />} />
            <Route path="*" element={<PrivateRoute><DefaultLayout /></PrivateRoute>} />
          </Routes>
        </Suspense>
      </HashRouter>

    )
  }
}

export default App
